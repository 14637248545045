import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { config } from '@env/environment';

@Component({
  selector: 'app-post-create-inline',
  templateUrl: './post-create-inline.component.html',
  styleUrls: ['./post-create-inline.component.scss'],
})
export class PostCreateInlineComponent {
  prefix = config.prefix;
  tab = this.prefix === 'ea' ? 2 : 0;

  @Input() tabs: string[] = ['Video', 'Audio', 'Text'];

  @Input() heading = $localize`New Post`;
  @Input() chat: any = null;
  @Input() group: any = null;
  @Input() title: string = null;
  @Input() parentId: string = null;
  @Input() categoryId: number = null;
  @Input() target: string = null;
  @Input() userId: string = null;
  @Input() appearance: string = null;
  @Input() where: string = null;

  @Output() public uploadComplete = new EventEmitter();

  constructor(private router: Router) {}

  get cliptype() {
    return this.tabs[this.tab];
  }

  dismissAction() {}

  switchTab(tab) {
    this.tab = tab;
  }

  uploadCompleteAction() {
    this.uploadComplete.emit();
  }

  upload() {
    this.router.navigate(['/combined/video-upload'], { queryParams: { returnUrl: this.router.url } });
  }
}
