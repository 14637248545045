<div class="postpopup" [class.animate]="!inModal" [class.popup]="!inModal">
  <div class="postpopup-header">
    <h1>{{ heading }}</h1>
    <div class="ui-popup-close" (click)="dismissAction()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="postpopup-cliptype" *ngIf="tabs.length > 1">
    <ul *ngIf="prefix === 'wal'" class="nav nav-tabs">
      <li class="nav-item" *ngIf="tabs.includes('Video')">
        <a
          class="nav-link"
          [class.active]="tab === tabs.indexOf('Video')"
          (click)="switchTab(tabs.indexOf('Video'))"
          i18n
          >Video</a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Audio')">
        <a
          class="nav-link"
          [class.active]="tab === tabs.indexOf('Audio')"
          (click)="switchTab(tabs.indexOf('Audio'))"
          i18n
          >Audio</a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Text')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Text')" (click)="switchTab(tabs.indexOf('Text'))" i18n
          >Text</a
        >
      </li>
    </ul>
    <ul *ngIf="prefix === 'ea'" class="nav nav-tabs">
      <li class="nav-item" *ngIf="tabs.includes('Text')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Text')" (click)="switchTab(tabs.indexOf('Text'))" i18n
          >Text</a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Audio')">
        <a
          class="nav-link"
          [class.active]="tab === tabs.indexOf('Audio')"
          (click)="switchTab(tabs.indexOf('Audio'))"
          i18n
          >Audio</a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Video')">
        <a
          class="nav-link"
          [class.active]="tab === tabs.indexOf('Video')"
          (click)="switchTab(tabs.indexOf('Video'))"
          i18n
          >Video</a
        >
      </li>
    </ul>
  </div>

  <div class="postpopup-form">
    <app-post-create-form
      [clipType]="cliptype"
      [chat]="chat"
      [group]="group"
      [title]="title"
      [parentId]="parentId"
      [categoryId]="categoryId"
      [target]="target"
      [userId]="userId"
      (uploadComplete)="uploadCompleteAction()"
      (cancelAction)="dismissAction()"
    ></app-post-create-form>
  </div>
</div>
