<div class="postinline">
  <div class="postinline-cliptype" *ngIf="tabs.length > 1">
    <ul *ngIf="prefix === 'wal'" class="nav nav-tabs">
      <li class="nav-item" *ngIf="tabs.includes('Video')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Video')" (click)="switchTab(tabs.indexOf('Video'))"
          ><mdi name="PostVideo"></mdi>&nbsp;<span i18n>Video</span></a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Audio')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Audio')" (click)="switchTab(tabs.indexOf('Audio'))"
          ><mdi name="PostAudio"></mdi>&nbsp;<span i18n>Audio</span></a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Text')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Text')" (click)="switchTab(tabs.indexOf('Text'))"
          ><mdi name="PostText"></mdi>&nbsp;<span i18n>Text</span></a
        >
      </li>
    </ul>
    <ul *ngIf="prefix === 'ea'" class="nav nav-tabs">
      <li class="nav-item" *ngIf="tabs.includes('Text')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Text')" (click)="switchTab(tabs.indexOf('Text'))"
          ><mdi name="PostText"></mdi>&nbsp;<span i18n>Text</span></a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Audio')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Audio')" (click)="switchTab(tabs.indexOf('Audio'))"
          ><mdi name="PostAudio"></mdi>&nbsp;<span i18n>Audio</span></a
        >
      </li>
      <li class="nav-item" *ngIf="tabs.includes('Video')">
        <a class="nav-link" [class.active]="tab === tabs.indexOf('Video')" (click)="switchTab(tabs.indexOf('Video'))"
          ><mdi name="PostVideo"></mdi>&nbsp;<span i18n>Video</span></a
        >
      </li>
    </ul>

    <div class="upload-video" (click)="upload()">
      <mdi name="Upload"></mdi>
    </div>
  </div>

  <div class="postinline-form">
    <app-post-create-form
      [clipType]="cliptype"
      [chat]="chat"
      [group]="group"
      [title]="title"
      [parentId]="parentId"
      [categoryId]="categoryId"
      [target]="target"
      [userId]="userId"
      [appearance]="appearance"
      [where]="where"
      cancelButtonText="Reset"
      (cancelAction)="dismissAction()"
      (uploadComplete)="uploadCompleteAction()"
    ></app-post-create-form>
  </div>
</div>
