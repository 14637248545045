import { endpoints } from './endpoints';
import { config, domain, isLocalDomain, urlProtocol } from './config.ea';

const URL = 'https://api-prod.eventanywhere.com/v1/';
const audioURL = 'https://file-prod.eventanywhere.com/';
const imageURL = 'https://file-prod.eventanywhere.com/';

const bucket = 'https://storage.googleapis.com/eaprod.appspot.com/';
const learnMore = 'https://eventanywhere.com/';
const terms = 'https://eventanywhere.com/subscription-agreement-terms-and-conditions/';
const policy = 'https://eventanywhere.com/privacy/';

const awsUrl = 'https://s3-eu-west-1.amazonaws.com/eventanywhere.video/';

const environment = {
  production: true,
  version: require('../../package.json').version,
};

const videoroomConfig = {
  url: 'wss://janus-wal-prod.eventanywhere.com/',
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:stun.services.mozilla.com' }],
};

const firebaseConfig = {
  firebase: {
    apiKey: 'AIzaSyCexK1tjSk98RdhNHkD5CywDFnQ9KOO24Q',
    authDomain: 'eaprod.firebaseapp.com',
    databaseURL: 'https://eaprod.firebaseio.com',
    projectId: 'eaprod',
    storageBucket: 'eaprod.appspot.com',
    messagingSenderId: '398318068281',
    appId: '1:398318068281:web:a2b797510177aad715e3e8',
    measurementId: 'G-K6594D2SYH',
  },
  dynamicLink: null,
};

const configUrl = (function () {
  const obj = {
    domain,
    isLocalDomain,
    urlProtocol,
    terms,
    policy,
    learnMore,
    URL,
    audioURL,
    imageURL,
    audioFS: `${audioURL}audio/`,
    audioImageFS: `${audioURL}audioimage/`,
    imageFS: `${imageURL}image/`,
    thumbnailFS: `${imageURL}thumbnail/`,
    host: window.location.origin,
    bucketURL: bucket,
    bucketImage: `${bucket}image/`,
    bucketCompany: `${bucket}company/`,
    googleAnalytics: 'UA-89700113-1',
    videoUrlAWS: awsUrl,
    bucketCompanyAudio: 'https://s3-eu-west-1.amazonaws.com/eventanywhere.company/audio',
    bucketCompanyVideo: 'https://s3-eu-west-1.amazonaws.com/eventanywhere.company/video',
    bucketSponsor: 'https://s3-eu-west-1.amazonaws.com/eventanywhere.sponsor/',
    bucketRoomImage: 'https://s3-eu-west-1.amazonaws.com/eventanywhere.roomimage/',
    bucketRecordings: 'https://s3.eu-west-1.amazonaws.com/eventanywhere.recordings/',
    awsUrl,
    logRocketAppId: 'frzllm/eventanywhere',
    reCaptureSiteKey: '6LciC7MaAAAAAJy0tKfGJb8iGJ0ocD4FyT7GMxUr',
    gtmId: 'GTM-M5X3WHX',
  };

  return Object.assign(obj, endpoints(URL, audioURL));
})();

export { environment, firebaseConfig, configUrl, config, videoroomConfig };
