import { Feature, SocialLogin } from '../app/shared/model';
import * as psl from 'psl';

export const config = {
  features: [
    Feature.LiveVideo,
    Feature.Multimodal,
    Feature.Notifications,
    Feature.Roulette,
    Feature.LiveStage,
    Feature.People,
    Feature.Chat,
    Feature.PublicTimeline,
    Feature.Reception,
    Feature.ExpoHall,
    Feature.MyVideos,
    Feature.HostAFreeEventButton,
  ],
  homeFeature: Feature.Reception,
  title: 'Event Anywhere',
  enquiryEmail: 'info@eventanywhere.com',
  websiteUrl: 'https://eventanywhere.com',
  prefix: 'ea',
  defaultAudioLimit: 60000,
  globalSubdomain: 'app',
  navInlineChatsGroups: false,
  sentryDSN: 'https://ae00baa8aba54d5a9e5758d51238d443@o23717.ingest.sentry.io/5619307',
  socialLogin: [SocialLogin.Google],
  loginAsGuest: false,
};

export const isLocalDomain =
  window.location.hostname === 'localhost' ||
  window.location.hostname.match(/^192\.168/) ||
  window.location.hostname.match(/^10\.0/);

export const domain = isLocalDomain ? window.location.hostname : psl.parse(window.location.host).domain;

export const urlProtocol = location.protocol;
